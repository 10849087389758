@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600&display=swap");

@font-face {
  font-family: "Readex Pro", sans-serif;
  src: url("../../../src/assets/fonts/ReadexPro-Regular.woff2");
}

* {
  box-sizing: border-box;
}

/* html, body {
     font-family: 'ReadexPro-Regular', sans-serif !important;
}
 */
.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-19 {
  font-size: 19px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-24 {
  font-size: 24px;
}

.show-cursor {
  cursor: pointer;
}

/* header css start */
.pf-header-row {
  background-image: url("../images/about-top-bg.png");
  background-repeat: no-repeat;
  background-size: inherit;
  background-position: right;
}

.header-ig-logo {
  /* height: 43px; */
  width: 270px;
}

.header-momentum-logo {
  width: 270px;
}

.header-span {
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.header-span.active,
.mb-menu.active {
  filter: hue-rotate(230deg);
}

.header-span img {
  width: 20px;
  height: 20px;
}

.add-apps-loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 350px !important;
}

.p-overlaypanel {
  min-height: 350px !important;
}

/* header css end */
.validation-div .css-1o07z2d {
  border-color: #dc3545 !important;
}

.highlight-div .css-1o07z2d {
  border-color: #a3d661 !important;
  background-color: #f0ffdb;
}

.fenris-logo {
  height: 30px;
  margin-right: 10px;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 89.7vh;
}

.wrapper-main-row {
  display: flex;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  padding: 10px 30px;
}

.main-content-row {
  min-height: 75vh;
  display: flex;
}

.sidebar-col {
  border-radius: 8px 0px 0px 8px;
  flex: 2;
}

.main-content-col {
  flex: 10;
  border-radius: 0px 8px 8px 0px;
}

/* sidebar css start */
.pf-sidebar-menu {
  font-size: 16px;
  display: flex;
  padding-left: 20px !important;
  padding: 10px;
  align-items: center;
}

.pf-sidebar-img {
  height: 22px;
  width: 25px;
  padding-right: 5px;
}

.coming-soon-block {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.coming-soon-title {
  text-align: center;
  font-weight: bold;
}

/* sidebar css end */

/* middle header css start */
.middle-header-logo {
  height: 30px;
  width: 30px;
}

.middle-header-col {
  border-radius: 10px;
  padding: 10px 7px;
  display: inline-block;
  margin-right: 10px;
  width: 10%;
  margin-bottom: 10px;
}

.middle-header-col.active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px 7px;
  margin-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}

.middle-header-title {
  font-size: 14px;
  margin-left: 5px;
}

/* middle header css end */
/* main content css start */
.no-primary-ams {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.client-search-row,
.history-search-row {
  padding: 25px 20px;
  display: flex;
  align-items: center;
}

.search-bar {
  display: flex;
}

.search-area {
  border: 1px solid;
  width: 263px;
  height: 32px;
  padding-left: 5px;
}

.search-area input::placeholder,
.comment-text::placeholder {
  font-size: 12px !important;
}

.search-btn {
  border-radius: 1px 10px 10px 1px !important;
  font-size: 14px !important;
  height: 2.19em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.pf-secondary-btn {
  border-radius: 8px !important;
}

.save-btn {
  border-radius: 8px !important;
  margin-right: 2px !important;
}

.pf-primary-btn {
  border-radius: 8px !important;
}

.history-response-btns .pf-primary-btn,
.setting-btn-position .pf-primary-btn {
  border-radius: 8px !important;
}

.history-response-btns .pf-primary-btn:hover,
.setting-btn-position .pf-primary-btn:hover {
  border-radius: 8px !important;
}

.update-client-btn-div,
.client-search-btn-col {
  display: flex;
  align-items: center;
}

.landing-page-blocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  min-height: 180px;
  align-items: center;
}

.landing-page-img {
  height: 40px;
  margin-bottom: 10px;
}

.landing-page-addapp-img {
  text-align: center;
}

.landing-page-title2 {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.landing-content-wrapper {
  display: block;
  padding: 2%;
}

.landing-block-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  z-index: 1;
}

.landing-block-menu img {
  margin-bottom: 5px;
  height: 25px;
  width: 25px;
}

.finance-company-dropdown {
  width: 100%;
}

.finance-company-dropdown span.p-dropdown-label.p-inputtext.p-placeholder,
.finance-company-dropdown span.p-dropdown-label.p-inputtext {
  font-size: 12px;
  padding: 8px;
}

.finance-company-dropdown .p-icon {
  height: 12px;
  width: 12px;
}
.billing-payment-history .p-datatable .p-datatable-thead > tr > th,
.billing-payment-history .p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px;
}
.billing-payment-history tbody td {
  padding: 4px 5px 4px 10px !important;
  height: 30px !important;
}
.input-field input {
  height: 30px !important;
}

/* datatable css start */
.history-unavailable {
  display: flex;
  justify-content: center;
  align-items: center;
}
.history-table {
  width: 100%;
  overflow-x: auto;
}

.history-table thead.p-datatable-thead tr {
  font-size: 12px;
}

.history-table .p-datatable > .p-datatable-wrapper {
  overflow: visible;
}

.history-table thead.p-datatable-thead th {
  padding: 5px !important;
  border-bottom: none !important;
}

.history-table .p-column-header-content {
  margin: 2px;
}

.sent-to-ams-icon {
  border-radius: 55%;
  padding: 4px;
  margin-right: 5px;
  font-size: 10px !important;
}

.vehicle-classification-criteria-info {
  cursor: pointer;
}

.indicative-icon {
  border-radius: 55%;
  padding: 3px 7px;
  margin-right: 5px;
  font-size: 10px !important;
}

.history-table tbody {
  font-size: 14px;
}
tr.p-datatable-emptymessage td {
  text-align: center !important;
}
.history-table .p-datatable .p-datatable-tbody > tr > td {
  padding: 7px;
}

.expander-height button {
  height: 20px !important;
  width: 20px !important;
  padding: 4px !important;
}

/* datatable css end */
.pf-company-col {
  border-radius: 10px;
  flex: 12;
  margin-bottom: 10px;
}

.pf-setting-inputs {
  padding: 7px;
}

.pf-setting-title {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  padding: 0.5rem !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

/* .p-dialog .p-dialog-header-icons {
  display: inline-flex !important;
}  */

.counter {
  float: right;
}

.counter-color {
  font-weight: 700;
}

.feedback-panel,
.addapps-panel {
  width: 300px;
}

.feedback-panel .p-overlaypanel-content,
.addapps-panel .p-overlaypanel-content {
  padding: 0 !important;
}

.feedback-header {
  padding: 10px;
  margin-bottom: 10px;
}

.menu-panel {
  min-height: 70px !important;
  min-width: 100px;
}

.bookmarked-icon span {
  cursor: pointer;
}

.bookmark-icon span {
  cursor: pointer;
}

.ams-notselected-icon {
  cursor: pointer;
}

.ams-selected-icon {
  cursor: pointer;
}

.primary-ams-main-block {
  border-radius: 10px;
}

.common-setting-block {
  border-radius: 10px;
  padding-bottom: 35px;
}

.gigcode-block {
  border-radius: 10px;
  margin-top: 10px;
}

.height-large {
  min-height: 300px;
  position: relative;
}

.height-small {
  height: 320px;
  position: relative;
}

.more-block-row {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.ams-header {
  padding: 8px;
  font-weight: normal;
}

.bookmark-history-icon-after-save {
  font-size: 20px;
  cursor: pointer;
}

.hawksoft-settings-eye-icon,
.nc-eye-icon {
  position: absolute;
  top: 30px;
  right: 20px;
}

.login-eye-icon {
  position: absolute;
  top: 5px;
  right: 20px;
}

.more-block {
  padding: 5px 10px;
  border-radius: 0px 0px 10px 10px;
}

.more-block img {
  cursor: pointer;
}

.login-box {
  width: 28%;
  padding: 40px 15px;
  border-radius: 10px;
}

.terms-box {
  width: 50%;
  margin: 10px 30px;
  padding: 40px 15px;
  border-radius: 10px;
}

.social-link-block {
  cursor: pointer;
}

.social-link-block img {
  height: 18px;
  width: 18px;
}

.social-link-block:hover {
  color: #00add9;
  text-decoration: underline;
}

.login-link {
  letter-spacing: 1px;
}

.finance-company-name {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex !important;
  align-items: center;
  padding: 2px;
}

.setting-btn-position {
  position: absolute;
  width: 100%;
  bottom: 5px;
}

#client-search {
  position: absolute;
  width: 100%;
  height: fit-content;
  max-height: 200px;
  min-height: 35px;
  margin-top: 0px;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

#client-search > div {
  min-height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: 0.1s background;
}

.client-loader-div {
  border-bottom: none !important;
}

.canopy-clientselector-dropdown {
  position: absolute;
  width: calc(100% - 25px);
  /* height: fit-content; */
  max-height: 200px;
  min-height: 35px;
  margin-top: 0px;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

.client-search-width {
  top: 30px;
}

.canopy-clientselector-dropdown > div {
  min-height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: 0.1s background;
}

.pf-setting-col {
  border-radius: 10px;
  min-height: 330px;
  position: relative;
}

.finance-company-name .p-inputswitch {
  height: 25px !important;
}

.effective-data-span {
  display: inline-block !important;
  margin-left: 25px;
}

.policy-type-heading {
  font-weight: bold;
}

.divider {
  padding-top: 10px;
}

.accept-checkbox .css-136kakq-Checkbox {
  display: flex;
  align-items: center;
}

.total-premium-div {
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
}

.subpolicy-block label,
.showmore-block label {
  margin-bottom: 3px;
}

.subpolicy-block input,
.showmore-block input {
  font-size: 12px !important;
}

.authenticating-block {
  border-radius: 8px;
  padding: 10px;
  font-size: 24px;
  text-align: center !important;
}

.not-authorize-text {
  font-size: 24px;
}

.policy-name-tab {
  margin-top: 10px;
  padding: 10px 15px 8px 15px;
}

.policy-type {
  border: none !important;
}

.policy-name-tab span {
  border-bottom: none !important;
  padding: 12px 15px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
}

.policy-name-span {
  position: relative;
}

.remove-policy-icon {
  position: absolute;
  top: -2px;
  right: 3px;
  font-weight: 700;
  cursor: pointer;
}

.policydetails input::placeholder {
  color: #000 !important;
}

.policy-name-span.active {
  padding: 13px 15px;
}

.quote-response-block {
  border-radius: 10px;
}

.quote-middle-block {
  min-height: 227px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.quote-heading {
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0 !important;
}

.response-btn-div {
  width: 100%;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-evenly;
}

.history-expanded-block {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.expanded-row-button-block {
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.expanded-row-button-block button {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.final-quotes-table td {
  padding: 5px 10px !important;
}

.progress-bar {
  position: absolute;
  top: -60px;
}

.history-details-font {
  font-size: 14px;
}

.display-for-desktop {
  display: block !important;
}

.display-for-mobile {
  display: none !important;
}

.display-for-mobile-flex {
  display: none !important;
}

.display-for-desktop-flex {
  display: inline-flex !important;
}

.details-border {
  border-top: none;
}

.div-blur-loading {
  filter: blur(3px);
}

.response-btn-div .col-lg-12 {
  width: 50%;
}

.response-btn-div .pf-primary-btn {
  width: 90% !important;
}

.setup-landing-page {
  min-height: 89vh;
}

.copyright-block {
  text-align: end;
}

.back-btn-div {
  flex: 0 0 !important;
}

.profile-box {
  border-radius: 10px;
  padding: 10px;
  min-height: 80vh;
  width: 98%;
  padding: 30px;
}

.profile-title {
  padding: 7px;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.change-password-new-eye-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 15%;
}

.change-password-confirm-eye-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 15%;
}

.profile-info-icon {
  font-size: 12px;
  padding: 10px;
}

.profile-text-area:focus-visible {
  border: none !important;
}

.client-name-span {
  margin-left: 20px;
}

/* .history-searchbtn-div {
  height: 30px !important;
} */

.setup-info-div {
  width: 40%;
  margin: 0 auto;
}

.get-started-div {
  margin-top: 10%;
  margin-bottom: 5%;
}

.started-btn {
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}

.skip-link {
  font-size: 14px;
  cursor: pointer;
}

.setup-height-div {
  min-height: 81vh;
  margin: 0 auto;
  width: 100%;
}

.Ams-logo {
  padding: 10px;
}

.Ams-logo img {
  width: 100px;
}

.ams-name-span {
  font-weight: 500;
  font-size: 16px;
}

.wizard-ams-div {
  margin-bottom: 10px;
  padding: 10px;
}

.credentials-div {
  padding: 10px;
}

.credential-field-width {
  width: 40%;
}

.eye-icon {
  position: absolute;
  top: 55%;
  right: 21px;
  transform: translateY(-50%);
  cursor: pointer;
}

.widget-block {
  display: flex;
  align-items: center;
  padding: 10px 2px;
  font-weight: 600;
}

.select-widget-link {
  text-decoration: underline;
  margin-right: 20px;
  font-size: 12px;
  cursor: pointer;
}

.company-list-block {
  font-size: 14px;
}

.finance-company {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-company {
  padding: 10px;
  margin-bottom: 10px;
}

.not-selected-company {
  padding: 10px;
  margin-bottom: 10px;
}

.select-company-hover:hover {
  background-color: #fff;
}

.setup-btn-position {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.setup-right-side {
  margin-left: 20px;
}

.company-list-setup-mainpage,
.company-setting-setup-mainpage {
  margin-bottom: 0.5rem;
}

.setting-setup-width {
  width: 50%;
}

.company-list-setup-mainpage,
.company-setting-setup-mainpage {
  min-height: 50vh;
}

.ams-agency-setup-main-page {
  margin-bottom: 10%;
}

.sso-loader {
  text-align: center;
}

.app-skeleton {
  min-height: 100vh !important;
}

.no-client-msg {
  align-items: center;
  display: flex;
  min-height: 75%;
  opacity: 0.5;
}

.company-name-div:hover {
  cursor: pointer;
}

.copy-icon {
  height: 16px;
}

textarea {
  resize: none !important;
}
.bundle-checkbox {
  width: 20px;
  height: 20px;
}
/* main content css end */
/* media query start */
@media (max-width: 1550px) and (min-width: 1290px) {
  .pf-setting-col .pf-primary-btn {
    padding: 0 !important;
    font-size: 12px;
  }

  .only-landing-wrapper,
  .setup-landing-page {
    min-height: 86vh !important;
  }

  .pf-setting-col {
    min-height: 343px;
  }

  .landing-block-menu {
    font-size: 10px;
  }

  .new-quote-main-page .main-content-row,
  .fenris-main-page .main-content-row,
  .pf-saved-quote-main-page .main-content-row,
  .pf-history-main-page .main-content-row {
    min-height: 71vh;
  }

  .new-quote-main-page .content-wrapper,
  .fenris-main-page .content-wrapper,
  .pf-saved-quote-main-page .content-wrapper,
  .pf-history-main-page .content-wrapper,
  .nowcerts-sso-page .content-wrapper {
    min-height: 86vh !important;
  }

  .startup-page .content-wrapper {
    min-height: 86vh !important;
  }

  .profile-main-page .content-wrapper {
    min-height: 85.5vh !important;
  }

  .profile-box {
    min-height: 76vh !important;
  }

  .response-btn-div {
    display: inline;
  }

  .login-page .content-wrapper {
    min-height: 86vh;
  }

  .ams-agency-setup-main-page {
    margin-bottom: 10%;
  }

  .credential-field-width {
    width: 50%;
  }

  .setup-height-div {
    min-height: 77vh;
  }

  .company-list-setup-mainpage {
    min-height: 50vh;
  }
}

@media (max-width: 1281px) and (min-width: 1024px) {
  .fenris-feedback-panel {
    max-width: 30% !important;
  }
  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 10%;
  }

  .credential-field-width {
    width: 60%;
  }

  .setup-height-div {
    min-height: 74vh;
  }

  .setting-setup-width {
    width: 75%;
  }

  .only-landing-wrapper,
  .setup-landing-page {
    min-height: 83vh !important;
  }

  .login-page .content-wrapper {
    min-height: 83vh;
  }

  .startup-page .content-wrapper {
    min-height: 83vh !important;
  }

  .history-expanded-block .pf-primary-btn span:first-child {
    display: none;
  }

  .landing-block-menu {
    font-size: 9px;
  }

  .middle-header-col {
    width: 14%;
  }

  .response-btn-div {
    display: inline;
  }

  .setting-width-for-media {
    width: 50% !important;
  }

  .update-client-btn-div,
  .client-search-input-col,
  .client-search-btn-col {
    display: inline;
  }

  .new-quote-main-page .main-content-row,
  .fenris-main-page .main-content-row,
  .pf-saved-quote-main-page .main-content-row,
  .pf-history-main-page .main-content-row {
    min-height: 66vh !important;
  }

  .new-quote-main-page .content-wrapper,
  .fenris-main-page .content-wrapper,
  .pf-saved-quote-main-page .content-wrapper,
  .pf-history-main-page .content-wrapper {
    min-height: 88vh !important;
  }

  .pf-sidebar-menu {
    font-size: 14px;
  }

  .finance-company-name,
  .pf-setting-title,
  .vehicles-title {
    font-size: 12px;
  }

  .nowcerts-sso-page .content-wrapper {
    min-height: 80vh;
  }

  .linkuser-page .content-wrapper {
    min-height: 80vh;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  /* #vehicles-client-search{
    width: 200px;
  } */
  .fenris-feedback-panel {
    max-width: 50% !important;
  }
  .login-box {
    width: 45%;
  }

  .display-for-desktop-flex {
    display: none !important;
  }

  .back-btn-div {
    position: absolute;
    top: 0;
  }

  .content-wrapper {
    min-height: 90vh;
  }

  .main-content-row {
    min-height: 72vh;
  }

  .pf-sidebar-menu {
    font-size: 14px;
  }

  .middle-header-col {
    width: 20%;
  }

  .update-client-btn-div,
  .client-search-input-col,
  .client-search-btn-col {
    display: inline;
  }

  .response-btn-div {
    display: inline;
  }

  .display-for-desktop {
    display: none !important;
  }

  .display-for-mobile {
    display: block !important;
  }

  .display-for-mobile-flex {
    display: flex !important;
  }

  .expanded-row-button-block {
    flex-direction: row;
  }

  .expanded-row-button-block button {
    width: 100% !important;
    margin: 10px 2px !important;
  }

  .mobile-view-header-menu img {
    height: 20px;
    margin-right: 5px;
  }

  .mobile-view-header-menu {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  .setup-landing-page {
    min-height: 91vh !important;
  }

  .setup-right-side {
    margin-left: 0px;
    margin-top: 20px !important;
  }

  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 10%;
  }

  .credential-field-width {
    width: 70%;
  }

  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    min-height: 20vh;
  }

  .setting-setup-width {
    width: 75%;
  }
}

@media (max-width: 768px) and (min-width: 200px) {
  .fenris-feedback-panel {
    max-width: 50% !important;
  }
  .setup-right-side {
    margin-left: 0px;
    margin-top: 20px !important;
  }

  .setup-info-div {
    width: 100%;
  }

  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    min-height: 10vh;
  }

  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 10%;
  }

  .setting-setup-width {
    width: 100%;
  }

  .credential-field-width {
    width: 80%;
  }

  .sidebar-menu-title {
    display: none;
  }

  .profile-border {
    border-right: 0;
  }

  .back-btn-div {
    position: absolute;
    top: 0;
  }

  .display-for-mobile-flex {
    display: flex !important;
  }

  .history-expanded-block .pf-primary-btn span:first-child {
    display: none;
  }

  .setup-landing-page {
    min-height: 95vh !important;
  }

  .history-search-row {
    padding: 25px 5px;
  }

  .profile-box {
    padding: 10px;
  }

  .history-searchbtn-div {
    text-align: right;
  }

  .update-client-btn-div {
    text-align: end;
    display: block;
  }

  .client-search-input-col,
  .client-search-btn-col {
    display: block;
  }

  .login-box {
    width: 80%;
  }

  .middle-header-col {
    width: 44% !important;
  }

  .history-details-font {
    font-size: 11px;
  }

  .partner-logo-and-span {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .pf-sidebar-menu {
    text-align: center;
    padding: 10px !important;
  }

  .history-table tbody {
    font-size: 12px;
  }

  .wrapper-main-row {
    padding: 10px;
  }

  .terms-box {
    width: 100%;
  }

  .display-for-desktop {
    display: none !important;
  }

  .display-for-desktop-flex {
    display: none !important;
  }

  .display-for-mobile {
    display: block !important;
  }

  .expanded-row-button-block {
    flex-direction: row;
    display: block;
  }

  .expanded-row-button-block button {
    width: 100% !important;
    margin: 10px 2px !important;
  }

  .details-border {
    border-right: none;
  }

  .header-ig-logo {
    height: 30px;
    width: auto;
  }

  /* .header-momentum-logo{
          width: auto;
     } */
  .mobile-view-header-menu img {
    height: 20px;
    margin-right: 5px;
  }

  .mobile-view-header-menu {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  .pf-header-row {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .wrapper-below-div {
    margin-top: 60px !important;
  }

  .content-wrapper {
    min-height: 95vh;
  }

  .main-content-row {
    min-height: 71vh;
  }

  .copyright-block,
  .footer-copyright-div {
    text-align: center;
  }
}

@media (max-width: 426px) and (min-width: 200px) {
  .fenris-feedback-panel {
    max-width: 90% !important;
  }
  .setting-setup-width {
    width: 100%;
  }

  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    min-height: 10vh;
  }

  .credential-field-width {
    width: 100%;
  }

  .ams-agency-setup-main-page,
  .company-list-setup-mainpage,
  .company-setting-setup-mainpage {
    margin-bottom: 25%;
  }

  .response-btn-div {
    display: inline;
  }

  .accept-checkbox {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .header-ig-logo,
  .header-momentum-logo {
    width: 100%;
  }

  .client-name-span {
    margin-left: 5px;
  }

  .client-and-policy-title-row {
    font-size: 12px;
  }

  .add-apps-menu {
    display: none !important;
  }
}

/* media query end */

/* COI and Smart Scan CSS*/

.landing-page-coi-scan-icon {
  height: 52px;
  width: 52px;
  margin-bottom: 10px;
}

.coi-scan-middle-header-col.active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}

.coi-scan-middle-header-col {
  border-radius: 10px;
  padding: 10px 7px;
  display: inline-block;
  margin-right: 10px;
  width: 10%;
  margin-bottom: 10px;
}
.uploadNowcertsDoc25 {
  border: 2px dashed #42526e !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 80% !important; */
  /* margin-top: 1% !important; */
  background: #eeeeee !important;
  /* padding: 12%; */
  /* margin-left: 10%; */
  margin: 0 auto;
  min-height: 65vh;
}
.uploadNowcertscoi {
  border: 2px dashed #42526e !important;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80% !important;
  /* margin-top: 1% !important; */
  background: #eeeeee !important;
  /* padding: 12%; */
  /* margin-left: 10%; */
  margin: 0 auto;
  min-height: 65vh;
}
.browse-pdf {
  color: #4ab6cc;
  display: block;
  font-size: large;
  border: "solid 1px" !important;
  border-radius: "5px" !important;
  padding: "3px" !important;
}
.coi-newscan {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.coi-newscan .active {
  background-color: #fff;
  color: #77a13b;
}
.coi-info {
  display: flex;
  justify-content: center;
}
.history-refresh-button {
  padding-top: 12px;
  display: flex;
  justify-content: end;
}
.history-refresh-button-start {
  padding-top: 12px;
  display: flex;
  justify-content: start;
}
.show-pdf-icon {
  display: flex;
  font-size: 12px;
  padding: 10px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: white;
  width: 50%;
}
.drag-and-drop {
  color: black;
  font-size: large;
  font-weight: 800;
}
.uploaded {
  color: #268a6c;
}
.file-name {
  display: flex;
  align-items: center;
}
.preview-icon {
  margin-right: "15px";
}
.info {
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  padding: 6px;
  width: 56%;
}
.smart-scan-dropdown {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 25%;
}

.smartscan-tab {
  border-bottom: 3px solid #233646;
  margin-top: 10px;
  /* padding: 10px 15px 8px 15px; */
  padding: 5px 11px 10px 15px;
}

.smartscan-tab span {
  border-bottom: none !important;
  padding: 12px 15px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
  border: 1px solid #233646;
  color: #233646;
}

.smartscan-block {
  box-shadow: 0 0 20px rgb(51 51 51 / 10%);
  transition: 0.1s border, 0.3s transform, 0.3s -webkit-transform;
  margin: 10px 3px;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #fff;
  /* width: 90% !important; */
  /* margin-left: 38px !important; */
}

.smartscan-block:hover {
  border: 1px solid rgba(71, 18, 125, 1);
}
.smartscan-text {
  margin-top: 11px !important;
}
.coi-download-icon {
  display: flex;
}
.browse {
  color: rgb(74, 182, 204);
  display: block;
  font-size: large;
  border: 1px solid;
  border-radius: 5px;
  padding: 3px;
}
.filename {
  margin-left: 10px;
}
.page-loading {
  padding-top: 15px;
}
/* 
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
  background: #1ab394 !important;
} */
.onsubmit-info-show {
  display: flex;
  justify-content: center;
}
.smart-scan-dropdown {
  padding: 10px !important;
  border-radius: 8px !important;
  border-color: #dfe1e6 !important;
  color: #5c5a5a !important;
  width: 35%;
}
.use-this-value {
  right: 1%;
}
.update-ams-data {
  display: flex;
  justify-content: end;
}
.css-1k5ez1w[data-disabled][data-checked] {
  background-color: #00875a !important;
}

/*  fenris feedback  */
.feedback-container {
  display: flex;
  /* justify-content: space-around;
  margin-top: 20px; */
}

.thumbs-up-btn,
.thumbs-down-btn {
  font-size: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.fenris-feedback-panel {
  min-height: 70px !important;
  max-width: 20%;
}
.feedback-thumbs-up {
  font-size: 28px !important;
  margin-right: 10px;
  cursor: pointer;
}

.feedback-thumbs-up.active {
  color: green;
}
.feedback-thumbs-down {
  font-size: 28px !important;
  margin-right: 10px;
  cursor: pointer;
}
.feedback-thumbs-down.active {
  color: red;
}
.fix-width-support {
  min-width: 500px !important;
}
.history-refresh-button-start {
  padding-top: 12px;
  display: flex;
  justify-content: end;
}
.fenrisfeedback {
  display: flex;
  justify-content: center;
}
.pf-scan-client-search {
  display: flex;
  justify-content: flex-start;
  margin-left: 2px;
}
.SmarScanBetaBadge {
  margin-left: 1px;
  margin-bottom: 118px;
  position: absolute;
}
.pf-scan-Badge {
  position: absolute;
  left: -7px;
  top: 18px;
  background-color: #f70000;
  -webkit-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
  -moz-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
  -o-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
  -ms-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
  transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
  text-align: center;
  color: white;
  padding: 0 0.5em;
  border-top: 0px;
  line-height: 20px;
  margin: 0;
}

.pf-scan-Badge:after {
  content: " ";
  position: absolute;
  right: -19px;
  top: 0px;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f70000;
}

.pf-scan-Badge:before {
  content: " ";
  position: absolute;
  left: -19px;
  top: 0px;
  border-left: 20px solid transparent;
  border-bottom: 20px solid #f70000;
}

/* Vehicles  Css */
#vehicles-client-search {
  position: absolute !important;
  /* width: 252px; */
  height: fit-content !important;
  max-height: 200px;
  min-height: 35px;
  margin-top: 0px;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  z-index: 999;
}

#vehicles-client-search > div {
  min-height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: 0.1s background;
}

.vehicle-box-height {
  height: 52vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.vehicle-search-area {
  border: 1px solid;
  height: 32px;
  padding-left: 5px;
}

.vehicles-title{
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pf-scan-dropdown{
  padding: 10px !important;
    border-radius: 1px !important;
    border-color: #dfe1e6 !important;
    color: #5c5a5a !important;
    width: 100%;
}
.pf-scan-balance{
  display: flex;
  justify-content: end;
}
